<template>
  <div>
    <BrandChooseWindow />
    <!-- Start Header Area -->
   <Header>
      <img slot="logo" src="@/assets/images/logo/logo-all-dark.png" />
    </Header>
      <div
      class="bg_image bg_image--16"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="rn-page-title text-center pt--100">
              <h2 class="heading-title theme-gradient"></h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Start Column Area  -->
    <div v-if="$store.state.selectedInformation === 'white'" class="rn-column-area rn-section-gap bg_color--5">
      <v-container>
        <v-row>
          <v-col cols="12">
             <h2 class="tilte center">White</h2>
            <div class="single-column">
              <h4 class="tilte">One Full</h4>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum. You need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend toitrrepeat.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-if="$store.state.selectedInformation === 'blue'" class="rn-column-area rn-section-gap bg_color--5">
      <v-container>
        <v-row>
          <v-col cols="12">
             <h2 class="tilte center">Blue</h2>

            <div class="single-column">
              <h4 class="tilte">One Full</h4>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum. You need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend toitrrepeat.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-if="$store.state.selectedInformation === 'silver'" class="rn-column-area rn-section-gap bg_color--5">
      <v-container>
        <v-row>
          <v-col cols="12">
             <h2 class="tilte center">Silver</h2>

            <div class="single-column">
              <h4 class="tilte">One Full</h4>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum. You need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend toitrrepeat.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Column Area  -->
    <!-- Start Column Area  -->
    <div v-if="$store.state.selectedInformation === 'gold'" class="rn-column-area rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col
            md="6"
            sm="6"
            cols="12"
            v-for="(column, i) in oneHalfContent"
            :key="i"
            :class="column.class"
          >
             <h2 class="tilte center">Gold</h2>

            <div class="single-column">
              <h4 class="tilte">One Half</h4>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum. You need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend toitrrepeat.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "../components/header/HeaderOnePage";
  import Footer from "../components/footer/FooterTwo";
  import BrandChooseWindow from "@/components/brand/BrandChooseWindow";

  export default {
    components: {
      Header,
      Footer,
      BrandChooseWindow
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "Column",
            to: "",
            disabled: true,
          },
        ],
        oneHalfContent: [
          {
            class: "",
            title: "One Half",
            desc: `There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum. You need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend toitrrepeat.`,
          },
          {
            title: "One Half",
            class: "mt_sm--30",
            desc: `There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum. You need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend toitrrepeat.`,
          },
        ]
      };
    },
  };
</script>
